<template>
    <v-card flat class="mb-7 w-100">
        <v-card-text class="pa-5 pb-0">
            <div class="d-md-flex align-start">
                <span class="lstick"></span>
                <!-- Historial de Visitas -->
                <h3 class="title font-weight-regular">Estad&iacute;stica de Visitas</h3>
                <v-spacer></v-spacer>
                <div class="ml-auto" v-show="false">
                    <div style="width: 150px">
                    </div>
                </div>
            </div>
        </v-card-text>
        <div class="info">
            <v-row no-gutters v-if="!loading">
                <v-col cols="12" sm="4" md="4" lg="3">
                    <div class="pa-2 pl-5 info">
                        <h6 class="white--text font-weight-regular mb-1">Total de Visitas</h6>
                        <h3 class="white--text font-weight-regular mb-0">{{ totalVisits }}</h3>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="3">
                    <div class="pa-2 pl-5 info">
                        <h6 class="white--text font-weight-regular mb-1">M&oacute;viles</h6>
                        <h3 class="white--text font-weight-regular mb-0">{{ dataSerie[0] }}</h3>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="3">
                    <div class="pa-2 pl-5 info">
                        <h6 class="white--text font-weight-regular mb-1">Tablets</h6>
                        <h3 class="white--text font-weight-regular mb-0">{{ dataSerie[1] }}</h3>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="3">
                    <div class="pa-2 pl-5 info">
                        <h6 class="white--text font-weight-regular mb-1">Escritorios</h6>
                        <h3 class="white--text font-weight-regular mb-0">{{ dataSerie[2] }}</h3>
                    </div>
                </v-col>
            </v-row>
            <v-skeleton-loader v-else height="50px" type="image"></v-skeleton-loader>
        </div>

        <v-card-text class="pa-5">
            <div v-if="elementVisible && !loading">
                <vue-apex-charts type="bar" height="250" :options="SiteVisitChartData.SiteVisitChart.chartOptions"
                    :series="SiteVisitChartData.SiteVisitChart.series">
                </vue-apex-charts>
            </div>
            <v-skeleton-loader v-else type="image"></v-skeleton-loader>
        </v-card-text>
    </v-card>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";
    import siteVisitOverviewData from "./SiteVisitOverviewData";
    import dashBoardService from '@/providers/DashboardService';

    export default {
        name: "SiteVisitOverview",
        components: {
            VueApexCharts,
        },
        data: () => ({
            SiteVisitChartData: siteVisitOverviewData,
            elementVisible: false,
            dataSerie: [],
            totalVisits: 0,
            loading: true,
        }),
        methods: {
            getVisitSeparation() {
                this.loading = true;
                dashBoardService.getTotaltVisits().then(value => {
                    this.totalVisits = value.total;
                    this.dataSerie.push(value.mobiles);
                    this.dataSerie.push(value.tablets);
                    this.dataSerie.push(value.desktops);
                    this.SiteVisitChartData.SiteVisitChart.series[0].data = this.dataSerie;
                }).catch(err=>console.log(err)).finally(()=>{
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.totalVisits = 0;
            this.dataSerie = [];
            this.getVisitSeparation();
            setTimeout(() => (this.elementVisible = true), 10);
        }
    };
</script>